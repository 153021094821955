import React from 'react'
import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import { BtnWrapper, FormWrapper, BoxWrapper, BoxinputWrapper} from "./form.style";
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image'
import Rightimg from '../img/blackbg.png';
import Logo from '../img/formlogo.png';
import { FormpageWrapper }from './form.style';
import Icon from 'react-icons-kit';
import {ic_keyboard_arrow_right} from 'react-icons-kit/md/ic_keyboard_arrow_right';
import {ic_keyboard_arrow_left} from 'react-icons-kit/md/ic_keyboard_arrow_left';
import Spinner from 'react-bootstrap/Spinner';
import { navigate } from 'gatsby';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const element = (
  <Spinner animation="border" size="sm" />
);

const animationblock = (
  <div className="animation-block"><Spinner animation="border" size="sm" /></div>
);

class FormThree extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      plan:  '',
      planqty: '',
      userexpected: '',
      currentStep: 3,
      renderloading: false,
      renderload: false, 
    }
  }

  handleSubmit = e => {
    e.preventDefault();
  };

  handleChange = event => {
    const {name, value} = event.target
      this.setState({
        [name]: value
      })    
  }

  _prev = () => {
    const { formData } = this.props
    let prevpath = "/";
    if(formData.step2Active) {
      prevpath = formData.step2SlugUrl;
    }
    navigate(prevpath);
  }

  componentDidMount() {
    console.log(window.history.state)
    if(window.history.state.plan) {
      this.setState({
        plan: window.history.state.plan, planqty: window.history.state.planqty
      })
    }else {
      navigate("/");
    }
  }

  _next = event => {
    let currentStep = this.state.currentStep

    const { plan, planqty, userexpected } = this.state
    const { formData } = this.props
    if(currentStep === 3 && `${userexpected}` === ""){
         alert(`Select value`)
    }else {
      this.setState({
        renderloading: true,
      })
      setTimeout(() => {
        let nextpath = "/";
        if(formData.step4Active) {
          nextpath = formData.step4SlugUrl;
        }else if(formData.step5Active) {
          nextpath = formData.step5SlugUrl;
        }else if(formData.step6Active) {
          nextpath = formData.step6SlugUrl;
        }

        navigate(nextpath,
          {
            state: {
              plan: plan,
              planqty: planqty,
              userexpected: userexpected,
            },
          }
        );
        currentStep = currentStep + 1
        this.setState({
          currentStep: currentStep, renderloading: false
        })
      }, 2000)
    } 
  }

  nextButton() {
    let currentStep = this.state.currentStep;
      return (
        <button 
          className={`btn btn-custom float-right ${this.state.renderloading ? "disable-effect" : ""}`}
          type="submit"  
          onClick={this._next}
          disabled={currentStep === 3 && this.state.userexpected  === "" ?  "disabled"  : ""}
          >
          Volgende 
          {this.state.renderloading ? (<span> {element} </span>) : (<Icon icon={ic_keyboard_arrow_right} className="list_icon" size={22}/> )}
        </button>        
      )
  }

  previousButton() {
    let currentStep = this.state.currentStep;
    if(currentStep !==1 && currentStep !== 5){
      return (
        <a 
          className="btn custom-link" 
          href="JavaScript:Void(0)"
          onClick={this._prev}>
        <Icon  icon={ic_keyboard_arrow_left} className="list_icon" size={22}/>
        Vorige
        </a>
      )
    }
    return null;
  }


  render() { 
    const data = this.props;  
    return (
      <React.Fragment>
        <FormpageWrapper>
          <Row>
            <Container fluid={true}>
            {this.state.renderload ? (<span> {animationblock} </span>) : ""}
              <Row>
                <Col xs="12" sm="9">
                  <div className="left-block">
                      <Row className="justify-content-md-center">
                        <Col xs="12" sm="6">
                            <div className="form-block">
                              <div className="logo">
                                <Img fluid={data.formData.logo.fluid} />  
                              </div>
                               <form onSubmit={this.handleSubmit}>
                                <Step3 
                                  currentStep={this.state.currentStep} 
                                  handleChange={this.handleChange}
                                  userexpected={this.state.userexpected}
                                  formData={data.formData}
                                />
                                {this.nextButton()}
                                {this.previousButton()}
                              </form>
                            </div>
                        </Col>
                      </Row>
                  </div>
                </Col>
                <Col xs="12" sm="3">
                  <div className="right-block">
                    <div className="blackbox">
                     <h3 className="sidebartitle">{data.sidebar.homepageFormTitle}</h3>
                       <div className="sidebarimages">
                         <ul>
                              {data.sidebar.formImages.map((items, i) => (
                                 <li>
                                  <Img fluid={items.fluid} />
                                </li>
                              ))} 
                          </ul>
                       </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </FormpageWrapper>
      </React.Fragment>
    );
  }
}

function Step3(props) { 
  return(
  <React.Fragment>
    <div className="stepthree">
     <div className="stepthead">
        <div
          dangerouslySetInnerHTML={{ __html: documentToHtmlString(props.formData.step3Description.json) }}
        />
     </div>
      <div className="form-group">
        <label htmlFor="userexpected"></label>
        <input
          className="form-control"
          name="userexpected"
          type="text"
          placeholder={props.formData.step3TextPlaceholder}
          value={props.userexpected}
          onChange={props.handleChange}
          required/>      
      </div>
    </div>
  </React.Fragment>
  );
}

export default FormThree;